import styled from 'styled-components'

const BackgroundShape = styled.div`
	background: #b0bec5;
	/* height: 200px; */
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100vw;
	/* padding-bottom: 20px; */
	margin-left: calc(-50vw + 50%);
	padding-top: ${props => props.paddingTop}px;
	padding-bottom: ${props => props.paddingBottom}px;

	&:after {
		content: '';
		display: block;
		height: 100%;
		background: #b0bec5;
		/* position: absolute; */
	}
`

export default BackgroundShape
