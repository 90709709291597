import styled from 'styled-components'

import Section from '../../Section'
import TitleBold from '../../TitleBold'

const BlockTitleSection = styled(Section)`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;

	&::after {
		content: '';
		display: block;
		background: ${props => (props.background ? '#b0bec5' : 'transparent')};
		width: 100vw;
		position: absolute;
		padding-top: ${props => props.paddingTop}px;
		padding-bottom: ${props => props.paddingBottom}px;
	}
`

const BlockTitleTitle = styled(TitleBold)`
	text-align: center;
	text-transform: uppercase;
	max-width: 100%;
	z-index: 2;
	margin: 0;
	padding-bottom: 10px;
`
const BlockTitleSubtitle = styled.div`
	z-index: 2;
	text-align: center;

	p {
		margin-bottom: 0;
	}
`

export { BlockTitleSection, BlockTitleTitle, BlockTitleSubtitle }
