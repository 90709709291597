import React from 'react'
import parse from 'html-react-parser'

import BackgroundShape from '../../BackgroundShape'

import { BlockTitleSection, BlockTitleTitle, BlockTitleSubtitle } from './BlockTitle.styled'

const BlockTitle = data => {
	if (!data.text && data.data) {
		data = data.data
	}
	return (
		<BlockTitleSection
			paddingBottom={data.paddingBottom}
			paddingTop={data.paddingTop}
			background={data.backgroundShape}
		>
			{data.text && <BlockTitleTitle>{parse(data.text)}</BlockTitleTitle>}
			{data.subtext && <BlockTitleSubtitle>{parse(data.subtext)}</BlockTitleSubtitle>}
			{/* {data.backgroundShape && <BackgroundShape paddingBottom={data.paddingBottom} paddingTop={data.paddingTop} />} */}
		</BlockTitleSection>
	)
}

export default BlockTitle
